import { useState, useEffect } from 'react';
import { Block } from 'types/page';
import { eventEmitter } from 'rx/rxState';
import ManipulatorActions from './ManipulatorActions/ManipulatorActions';

import s from './ToolbarManipulator.module.scss';

const TOP_SCREEN_THRESHOLD = 120;
const TOOLBAR_TOP_OFFSET = 55;
const TOOLBAR_BOTTOM_OFFSET = 15;

interface IProps {
  block: Block;
}

const ToolbarManipulator = (props: IProps) => {
  const [visible, setVisible] = useState(true);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [width, setWidth] = useState(100);

  const [, setState] = useState({});

  const isSection = props.block.type === 'Section';

  useEffect(() => {
    const subscription = eventEmitter.subscribe({
      next: (e: any) => {
        if (e.type === 'view-on-scroll' || e.type === 'update-view') {
          update();
        }
      },
    });
    update();

    const handleResize = () => {
      const view = document.getElementById('view');
      if (view) {
        setRight(window.innerWidth - view.getBoundingClientRect().right);
      }
    };

    const bookCanvas = document.getElementById('view-canvas-book');
    if (bookCanvas) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    let observer: ResizeObserver;
    if (props.block.ref.current) {
      observer = new ResizeObserver(update);
      observer.observe(props.block.ref.current);
    }

    // const premiums: ButtonType[] = [];

    // if (props.block.type === 'Text' || props.block.type === 'Image') {
    //   if (!currentUser?.scopes?.includes('BOOKLE_ADD_NEW_CHAPTER')) {
    //     // premiums.push(ButtonType.Reroll);
    //   }
    // }

    return () => {
      subscription.unsubscribe();
      if (bookCanvas) window.removeEventListener('resize', handleResize);
      if (observer) observer.disconnect();
    };
  }, []);

  const forceUpdate = () => setState({});

  const update = () => {
    if (props.block) {
      if (props.block.ref.current) {
        const element = props.block.ref.current;
        const rect = element.getBoundingClientRect();

        if (isSection) {
          setTop(rect.y + 10);
        } else {
          setLeft(rect.x);

          if (rect.y < TOP_SCREEN_THRESHOLD) {
            setTop(rect.y + rect.height + TOOLBAR_BOTTOM_OFFSET);
          } else {
            setTop(rect.y - TOOLBAR_TOP_OFFSET);
          }

          setWidth(rect.width);
        }
      }
    }
  };

  const handleToggleManipulatorVisibility = (value: boolean) => {
    setVisible(value);
    update();
    forceUpdate();
  };

  return (
    <div
      className={s.container}
      style={{
        top: top,
        left: isSection ? 'unset' : left,
        right: isSection ? (right > 0 ? right + 20 : 20) : 'unset',
        width: isSection ? 'unset' : width,
        pointerEvents: 'none',
      }}
    >
      <div style={{ display: visible ? 'block' : 'none' }}>
        <ManipulatorActions
          block={props.block}
          onToggleManipulatorVisibility={handleToggleManipulatorVisibility}
        />
      </div>
    </div>
  );
};

export default ToolbarManipulator;
